.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  font-size:60px !important;

}

.btn {
  width:240px;
  border-radius:50px;
}

.btn-secondary{
  --bs-btn-bg: #d8512000 !important;
  --bs-btn-hover-bg: #d85120 !important;
  --bs-btn-hover-color: white !important;

  --bs-btn-border-radius: 50px !important;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);

}


.cover-container {
  background-color: rgb(23, 37, 56);
}


a:-webkit-any-link {
  color: white;
}


svg {
  width: 60vw;
}
#wrapper{
  overflow: hidden;
  background-color: #1a1b29;
}

svg{
  padding-left: 40%;
}
