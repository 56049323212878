body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  font-weight: 500;
  font-style: normal;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(23, 37, 56);
}

code {
  font-family: "Roboto", sans-serif;

}


#root {
  height: 100vh;
  background-color: rgb(23, 37, 56);
}


.navbar {
  background-color: rgb(23, 37, 56);
}
